<template>
    <div class="container clearfix" v-if="company">
        <div class="header blue clearfix d-company">
            <h1 class="company lh-company">
                <span class="company-name">{{ company.name }}
                    <span v-if="filial != -1" v-html="filialName"></span>
                </span>
            </h1>
        </div>
        <h1>{{ company.test }}</h1>
        <div class="mt-2 thnk-sub-title align-title" v-if="company.expired">
            Сервис оценки для этой компании временно недоступен!
        </div>

        <div v-if="!company.expired">
            <div v-if="rating == -1">
                <div v-if="company.show_filials > 0 && filial == -1 || company.is_tips">
                    <div class="thnk-title align-title">
                        {{ company.page_0_1 }}
                    </div>
                    <div class="office-links">
                        <a @click="choiceFilial(idx)" class="office-link" v-for="(filial, idx) of company.filials" :key='idx'>{{
                            filial.name }}</a>
                    </div>
                    <div class="office-links" v-if=company.is_tips>
                        <a :href='company.tips_link' class="office-link">Оставить чаевые</a>
                    </div>
                    <p style='text-align: center;'>{{ company.page_2_3 }}</p>
                    <div class="social-icons" v-if="!company.empty_socials">
                        <div class="social-icons-title">Присоединяйтесь</div>
                        <div class="social-icons-content">
                            <a target="_blank" :href="company.socials.vk" v-if="company.socials.vk"><i
                                    class="fab fa-vk"></i></a>
                            <a target="_blank" :href="company.socials.fb" v-if="company.socials.fb"><i
                                    class="fab fa-facebook-f"></i></a>
                            <a target="_blank" :href="company.socials.i" v-if="company.socials.i"><i
                                    class="fab fa-instagram"></i></a>
                            <a target="_blank" :href="company.socials.ok" v-if="company.socials.ok"><i
                                    class="fab fa-odnoklassniki"></i></a>
                            <a target="_blank" :href="company.socials.youtube" v-if="company.socials.youtube"><i
                                    class="fab fa-youtube"></i></a>
                            <a target="_blank" :href="company.socials.twitter" v-if="company.socials.twitter"><i
                                    class="fab fa-twitter"></i></a>
                            <a target="_blank" :href="company.socials.whatsapp" v-if="company.socials.whatsapp"><i
                                    class="fab fa-whatsapp"></i></a>
                            <a target="_blank" :href="company.socials.telegram" v-if="company.socials.telegram"><i
                                    class="fab fa-telegram-plane"></i></a>
                        </div>
                    </div>

                </div>

                <div v-if="company.show_filials == 0 || filial >= 0">
                    <!-- TODO: to new coponent -->
                    <div v-if="voted() || company.uniq_id">
                        <div class="mt-2 thnk-sub-title">
                            Спасибо за Вашу оценку!
                        </div>

                        <div class="gift-box" v-if="company.special" style="margin-top: 20px;">
                            <div class="gift-title">{{ company.page_1_5 }}</div>
                            <p>{{ company.page_1_6 }}</p>
                            <a :href="gift()" target="_blank" class="btn-blue">{{ company.page_1_7 }}</a>
                        </div>
                    </div>
                    <!-- /TODO: to new coponent -->
                    <div v-else>
                        <div class="thnk-title auto">
                            {{ company.page_1_1 }}
                        </div>

                        <div style="text-align: center; margin-top: 16px;" v-if="company.funnel_img">
                            <img class="img-fluid" :src="'/storage/funnel_imgs/' + company.funnel_img" alt=""
                                style="height: 250px; margin-bottom: 16px;">
                        </div>

                        <div class="thnk-sub-title">
                            {{ company.page_1_2 }}
                        </div>

                        <div class="rate">
                            <div class="rate-title">{{ company.page_1_3 }}</div>
                            <div class="stars">
                                <div class="star-rating">
                                    <form class="star-rating__wrap" id="starRatingWrap">

                                        <span v-for="(t, idx) of templates[company.template_stars].template">
                                            <input class="star-rating__input" :id="'star-rating-' + idx" type="radio"
                                                name="rating" :value="idx" v-model="raitngNew">
                                            <label :for="'star-rating-' + idx" v-html="t"
                                                :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars"></label>
                                        </span>

                                    </form>
                                    <div style="text-align: center;">
                                        <button type="button" class="btn-blue btn-transparent"
                                            @click="sendRating()">
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="rate-bottom" v-if="!company.special">{{ company.page_1_4 }}</div>
                        </div>

                        <div class="gift-box" v-if="company.special">
                            <div class="gift-title">{{ company.page_1_5 }}</div>
                            <p>{{ company.page_1_6 }}</p>
                            <a :href="gift()" target="_blank" class="btn-blue">{{ company.page_1_7 }}</a>
                        </div>
                    </div>
                </div>
            </div>


            <div v-else-if="rating">
                <!-- Mystery shopper -->
                <div v-show="company.mystery_shopper">
                    <div class="rate">
                        <div v-if="company.mystery_shopper_point_1">
                            <div class="rate-title">{{ company.mystery_shopper_text_1 }}</div>
                            <div class="stars">
                                <div class="star-rating">
                                    <form class="star-rating__wrap" id="starRatingWrap1">
                                        <input class="star-rating__input" id="star-rating-1-1" type="radio" name="ratingMS"
                                            value="1" v-model="MSpoint1">
                                        <label for="star-rating-1-1"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>1</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-1-2" type="radio" name="ratingMS"
                                            value="2" v-model="MSpoint1">
                                        <label for="star-rating-1-2"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>2</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-1-3" type="radio" name="ratingMS"
                                            value="3" v-model="MSpoint1">
                                        <label for="star-rating-1-3"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>3</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-1-4" type="radio" name="ratingMS"
                                            value="4" v-model="MSpoint1">
                                        <label for="star-rating-1-4"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>4</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-1-5" type="radio" name="ratingMS"
                                            value="5" v-model="MSpoint1">
                                        <label for="star-rating-1-5"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>5</span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div v-if="company.mystery_shopper_point_2">
                            <div class="rate-title">{{ company.mystery_shopper_text_2 }}</div>
                            <div class="stars">
                                <div class="star-rating">
                                    <form class="star-rating__wrap" id="starRatingWrap2">
                                        <input class="star-rating__input" id="star-rating-2-1" type="radio" name="ratingMS"
                                            value="1" v-model="MSpoint2">
                                        <label for="star-rating-2-1"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>1</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-2-2" type="radio" name="ratingMS"
                                            value="2" v-model="MSpoint2">
                                        <label for="star-rating-2-2"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>2</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-2-3" type="radio" name="ratingMS"
                                            value="3" v-model="MSpoint2">
                                        <label for="star-rating-2-3"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>3</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-2-4" type="radio" name="ratingMS"
                                            value="4" v-model="MSpoint2">
                                        <label for="star-rating-2-4"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>4</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-2-5" type="radio" name="ratingMS"
                                            value="5" v-model="MSpoint2">
                                        <label for="star-rating-2-5"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>5</span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div v-if="company.mystery_shopper_point_3">
                            <div class="rate-title">{{ company.mystery_shopper_text_3 }}</div>
                            <div class="stars">
                                <div class="star-rating">
                                    <form class="star-rating__wrap" id="starRatingWrap3">
                                        <input class="star-rating__input" id="star-rating-3-1" type="radio" name="ratingMS"
                                            value="1" v-model="MSpoint3">
                                        <label for="star-rating-3-1"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>1</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-3-2" type="radio" name="ratingMS"
                                            value="2" v-model="MSpoint3">
                                        <label for="star-rating-3-2"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>2</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-3-3" type="radio" name="ratingMS"
                                            value="3" v-model="MSpoint3">
                                        <label for="star-rating-3-3"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>3</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-3-4" type="radio" name="ratingMS"
                                            value="4" v-model="MSpoint3">
                                        <label for="star-rating-3-4"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>4</span>
                                        </label>

                                        <input class="star-rating__input" id="star-rating-3-5" type="radio" name="ratingMS"
                                            value="5" v-model="MSpoint3">
                                        <label for="star-rating-3-5"
                                            :class="'mr-5 star-rating__ico ' + 'template' + company.template_stars">
                                            <span>5</span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div v-if="company.mystery_shopper_point_4">
                            <div class="rate-title">{{ company.mystery_shopper_text_4 }}</div>
                            <div class="stars">
                                <div class="star-rating">
                                    <div class="mystery-buttons">
                                        <form class="star-rating__wrap" id="starRatingWrap4">
                                            <input class="star-rating__input mystery-shopper-radio" id="star-rating-4-1"
                                                type="radio" name="ratingMS" value="Да" v-model="MSpoint4">
                                            <label for="star-rating-4-1"
                                                class="center-aligned btn-blue btn-transparent">Да</label>

                                            <input class="star-rating__input mystery-shopper-radio" id="star-rating-4-2"
                                                type="radio" name="ratingMS" value="Нет" v-model="MSpoint4">
                                            <label for="star-rating-4-2"
                                                class="center-aligned btn-blue btn-transparent">Нет</label>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="company.mystery_shopper_point_5">
                            <div class="rate-title">{{ company.mystery_shopper_text_5 }}</div>
                            <div class="stars">
                                <div class="star-rating">
                                    <div class="mystery-buttons">
                                        <form class="star-rating__wrap" id="starRatingWrap5">
                                            <textarea style="width: 100%" rows="5" cols="45" v-model="MSpoint5"></textarea>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style="text-align: center;">
                            <button type="button" class="btn-blue btn-transparent" @click="sendMysteryShopper()">
                                Отправить
                            </button>
                        </div>

                    </div>
                </div>

                <div v-if="!company.mystery_shopper">

                    <!-- TODO: to new component-->
                    <div v-if="isBadVote">
                        <div class="mt-2 thnk-sub-title">Спасибо за Вашу оценку!</div>
                    </div>

                    <div v-else>
                        <div v-if="company.redirect_stars <= rating && company.redirect_state != 'review'">
                            <div class="thnk-title small">
                                {{ company.page_2_1 }}
                            </div>
                            <div class="good-sub-title" v-html="company.page_2_2"></div>
                            <div v-if="sms !== undefined" class="reviews-btns">
                                <a :href="'/q/' + sms + '/' + link.platform" v-for="link of links()">
                                    <img :src="platforms[link.platform].image" alt="">
                                    <span>{{ platforms[link.platform].vote }}</span>
                                </a>
                            </div>
                            <div v-else class="reviews-btns">
                                <a :href="link.link" target="_blank" v-for="link of links()">
                                    <img :src="platforms[link.platform].image" alt="">
                                    <span>{{ platforms[link.platform].vote }}</span>
                                </a>
                            </div>

                            <div class="office-links">
                                <p>{{ company.page_2_3 }}</p>
                            </div>
                            <div class="social-icons" v-if="!company.empty_socials">
                                <div class="social-icons-title">Присоединяйтесь</div>
                                <div class="social-icons-content">
                                    <a target="_blank" :href="company.socials.vk" v-if="company.socials.vk"><i
                                            class="fab fa-vk"></i></a>
                                    <a target="_blank" :href="company.socials.fb" v-if="company.socials.fb"><i
                                            class="fab fa-facebook-f"></i></a>
                                    <a target="_blank" :href="company.socials.i" v-if="company.socials.i"><i
                                            class="fab fa-instagram"></i></a>
                                    <a target="_blank" :href="company.socials.ok" v-if="company.socials.ok"><i
                                            class="fab fa-odnoklassniki"></i></a>
                                    <a target="_blank" :href="company.socials.youtube" v-if="company.socials.youtube"><i
                                            class="fab fa-youtube"></i></a>
                                    <a target="_blank" :href="company.socials.twitter" v-if="company.socials.twitter"><i
                                            class="fab fa-twitter"></i></a>
                                    <a target="_blank" :href="company.socials.whatsapp" v-if="company.socials.whatsapp"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a target="_blank" :href="company.socials.telegram" v-if="company.socials.telegram"><i
                                            class="fab fa-telegram-plane"></i></a>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="form popup1">
                                <p>{{ company.page_3_1 }}</p>
                                <form class="feedbackForm">
                                    <div class="input-block">
                                        <textarea placeholder="Текст сообщения" name="vote-reason"
                                            v-model="vote_reason"></textarea>
                                    </div>

                                    <div v-if="company.callback_question">
                                        <p>{{ company.page_3_2 }}</p>
                                        <div class="radio-wrapper">
                                            <div>
                                                <input type="radio" name="agree" value="yes" id="agree1"
                                                    v-model="vote_agree" />
                                                <label for="agree1">Да</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="agree" value="no" id="agree2"
                                                    v-model="vote_agree" />
                                                <label for="agree2">Нет</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="vote_agree == 'yes' || !company.callback_question">
                                        <div class="input-block" v-if="company.field_name">
                                            <input type="text" v-model="vote_name" placeholder="Имя">
                                            <span>*</span>
                                        </div>
                                        <div class="input-block" v-if="company.field_email">
                                            <input type="email" v-model="vote_email" placeholder="Почта">
                                            <span>*</span>
                                        </div>
                                        <div class="input-block" v-if="company.field_phone">
                                            <input type="text" v-model="vote_phone" placeholder="Телефон">
                                            <span>*</span>
                                        </div>
                                        <div class="input-block" v-if="company.field_file">
                                            <input type="file" ref="file" name="upload_file" accept="video/*, image/*"
                                                placeholder="Файл" @change="fileUploadChange">
                                        </div>
                                    </div>

                                    <button type="button" class="center-aligned" @click="reason()" :disabled="isSend">
                                        Отправить
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer no-for-mobil">
                <!--                <a href="/oferta">Публичная оферта</a>-->
            </div>
        </div>
    </div>
</template>

<script>
let toastr = require('toastr');

export default {
    props: [
        'company_init',
        'platforms_init',
        'init',
        'templates_init',
        'sms',
        'api_init',
        'sms_id'
    ],
    data() {
        return {
            'company': null,
            'platforms': null,
            'templates': null,
            'filial': -1,
            'rating': -1,
            'raitngNew': '',
            'vote_reason': '',
            'vote_agree': 'yes',
            'vote_name': '',
            'vote_phone': '',
            'vote_email': '',
            'field_email': '',
            'field_phone': '',
            'field_file': '',
            'file_id': '',
            'isBadVote': false,
            'domain': 'https://reviewss.me/',
            // 'domain': 'http://127.0.0.1:8000/',
            // 'domain': 'http://test.reviewss.me/',
            'isSend': false,
            MSpoint1: '',
            MSpoint2: '',
            MSpoint3: '',
            MSpoint4: '',
            MSpoint5: '',
            'review': null
        }
    },
    mounted() {

        if (this.init !== undefined) {
            this.initCompany(this.init);
        } else {
            this.company = this.company_init;
            this.platforms = this.platforms_init;
            this.templates = this.templates_init;
        }
    },
    computed: {
        filialName() {
            return '&nbsp;&mdash;&nbsp;' + this.company.filials[this.filial].name;
        },
    },
    methods: {
        sendMysteryShopper() {
            this.company.mystery_shopper = false

            if (this.review) {
                let data = {}
                data.mystery_shopper_question_1 = this.MSpoint1
                data.mystery_shopper_question_text_1 = this.company.mystery_shopper_text_1
                data.mystery_shopper_question_2 = this.MSpoint2
                data.mystery_shopper_question_text_2 = this.company.mystery_shopper_text_2
                data.mystery_shopper_question_3 = this.MSpoint3
                data.mystery_shopper_question_text_3 = this.company.mystery_shopper_text_3
                data.mystery_shopper_question_4 = this.MSpoint4
                data.mystery_shopper_question_text_4 = this.company.mystery_shopper_text_4
                data.mystery_shopper_question_5 = this.MSpoint5
                data.mystery_shopper_question_text_5 = this.company.mystery_shopper_text_5
                data.review_id = this.review
                this.$http.post(this.domain + '/api/v1/widget/shopper', data, { responseType: 'json' })
                    .then(() => {

                    })
            }
        },
        sendRating() {
            if (this.company.redirect_state == "review") {
                this.rating = this.raitngNew;
            } else if (this.raitngNew >= this.company.redirect_stars) {
                this.sendVote({ rate: this.raitngNew, callback: 0 }, false);
            } else {
                this.rating = this.raitngNew;
            }
        },
        messageBox(type, caption, message) {
            let options =
            {
                "closeButton": true,
                "debug": false,
                "newestOnTop": false,
                "progressBar": true,
                "positionClass": this.toastrClass(),
                "preventDuplicates": false,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "5000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };

            if (type == "success")
                toastr.success(message, caption, options);
            else if (type == "error")
                toastr.error(message, caption, options);

        },
        toastrClass() {
            return window.innerWidth > 768 ? "toast-top-full-width" : "toast-bottom-full-width";
        },
        initCompany(slug) {
            let self = this;
            let data = {};
            data.slug = slug;
            data.sms = this.sms
            this.$http.post(this.domain + '/api/v1/widget/init', data, { responseType: 'json' })
                .then(function (response) {
                    self.company = response.body.company;
                    self.platforms = response.body.platforms;
                    self.templates = response.body.templates;
                }, response => {
                    let message = [];
                    let idx, idx2;
                    for (idx in response.body.errors) {
                        for (idx2 in response.body.errors[idx]) {
                            message.push(response.body.errors[idx][idx2])
                        }
                    }
                    console.log("ОШИБКА: reviewss.me", message.join("\n"))
                });
        },
        choiceFilial(idx) {
            this.filial = idx;
            let data = {};
            data.company = this.company.slug;
            data.filial = this.company.filials[idx].id;
            this.$http.post(this.domain + '/api/v1/widget/filial', data, { responseType: 'json' })
                .then(function (response) {
                }, response => {
                    let message = [];
                    let idx, idx2;
                    for (idx in response.body.errors) {
                        for (idx2 in response.body.errors[idx]) {
                            message.push(response.body.errors[idx][idx2])
                        }
                    }
                    console.log("ОШИБКА: reviewss.me", message.join("\n"))
                });
        },
        voted() {
            if (this.filial == -1) {
                return this.company.voted;
            }
            return this.company.filials[this.filial].voted;
        },
        links() {
            if (this.filial == -1) {
                return this.company.links;
            }
            return this.company.filials[this.filial].links;
        },
        gift() {
            return this.company.url + '/gift';
        },
        choiceRating(rating) {
            // if (this.company.redirect_state == "review") {
            //     this.rating = rating;
            // } else if (rating >= this.company.redirect_stars) {
            //     this.sendVote({ rate: rating, callback: 0 }, false);
            // } else {
            //     this.rating = rating;
            // }
        },
        uploadFile(event) {
            let files = event.target.files
            if (files.length) {
                this.avatar = files[0]
            }
        },
        async fileUploadChange() {
            let file = event.target.files[0]
            await this.fileUpload(file)
        },
        async fileUpload(file) {
            let form = new FormData()
            form.append('upload_file', file)
            await axios.post('/api/v1/widget/upload-file', form)
                .then(response => {
                    this.file_id = response.data.file.id
                })
                .catch()
        },
        reason() {
            this.sendVote({
                rate: this.rating,
                name: this.vote_name,
                phone: this.vote_phone,
                email: this.vote_email,
                review: this.vote_reason,
                agree: this.vote_agree,
                callback: 1,
                mystery_shopper_question_1: this.MSpoint1,
                mystery_shopper_question_2: this.MSpoint2,
                mystery_shopper_question_3: this.MSpoint3,
                mystery_shopper_question_4: this.MSpoint4,
                mystery_shopper_question_5: this.MSpoint5,
            }, true);
        },
        sendVote(data, isBadVote) {
            let self = this;
            this.isSend = true;
            if (this.company.api_uniq) {
                data.api_uniq = this.company.api_uniq
            }
            data.company = this.company.slug;
            if (this.filial != -1) {
                data.filial = this.company.filials[this.filial].id;
            }
            data.file_id = this.file_id
            data.sms = this.sms
            data.sms_id = this.sms_id
            this.$http.post(this.domain + '/api/v1/widget/vote', data, {})
                .then(function (response) {
                    if (
                        !isBadVote
                        && self.company.redirect_link !== ''
                        && self.company.redirect_link != null
                        && self.company.redirect_state === 'url'
                        && self.company.redirect_stars <= data.rate
                    ) {
                        window.location.href = self.company.redirect_link;
                    } else {
                        this.rating = data.rate;
                        self.isBadVote = isBadVote;
                        self.isSend = false;
                        if (this.filial != -1) {
                            data.filial = this.company.filials[this.filial].id;
                        }
                        self.review = response.body.review
                    }
                    self.review
                    // this.messageBox('success', 'Отзывы', 'Ваш голос учтен');
                }, response => {
                    let message = [];
                    let idx, idx2;
                    for (idx in response.body.errors) {
                        for (idx2 in response.body.errors[idx]) {
                            message.push(response.body.errors[idx][idx2])
                        }
                    }
                    console.log("ОШИБКА: reviewss.me", message.join("\n"))
                    self.isSend = false;
                });
        },
    }
}
</script>
